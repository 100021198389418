/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        

          jQuery('.load_more a').live('click', function(e){                     
            e.preventDefault();                                    
            var link = jQuery(this).attr('href');
            jQuery('.load_more').html('<a class="loader">Loading<span class="loader__dot">.</span><span class="loader__dot">.</span><span class="loader__dot">.</span></a>');
            $.get(link, function(data) {
              var post = $(".post-item ", data);              
              $('.post-content').append(post);              
              window.history.pushState("string", "Portfolio Url", link);
            });
            jQuery('.load_more').load(link+' .load_more a');
          });
          

          $('.lazy').Lazy();       


          AOS.init({
            // Global settings:
            disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
            startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
            initClassName: 'aos-init', // class applied after initialization
            animatedClassName: 'aos-animate', // class applied on animation
            useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
            disableMutationObserver: false, // disables automatic mutations' detections (advanced)
            debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
            throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)


            // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
            offset: 120, // offset (in px) from the original trigger point
            delay: 400, // values from 0 to 3000, with step 50ms
            duration: 600, // values from 0 to 3000, with step 50ms
            easing: 'ease-in', // default easing for AOS animations
            once: false, // whether animation should happen only once - while scrolling down
            mirror: true, // whether elements should animate out while scrolling past them
            anchorPlacement: 'center-center', // defines which position of the element regarding to window should trigger the animation

          });   

          
          $(document).on('click','.quantity .plus', function(e) {                                   
            $input = $(this).prev('input.qty');
            var val = parseInt($input.val());
            $input.val( val+1 ).change();
          });
          
          $(document).on('click','.quantity .minus', function(e) {               
              $input = $(this).next('input.qty');
              var val = parseInt($input.val());
              if (val > 0) {
                $input.val( val-1 ).change();
              } 
            });     

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        

        jQuery(document).ready(function ($) {
        
          $('body').addClass('loaded');  

              // Also can pass in optional settings block
              if ($('.rellax')[0]) {
                  var rellax = new Rellax('.rellax', {
                      speed: -3,
                      center: true,
                      wrapper: null,
                      round: true,
                      vertical: true,
                      horizontal: false
                  });
              }                      
      });

      }

    },
    // Home page
    'single_product': {
      init: function() {
        // JavaScript to be fired on the home page
        jQuery(document).ready(function($) {

            // The X/Pro "Added to cart" overlay    
            var notification = $('.x-cart-notification');

            $(".single_add_to_cart_button").on('click', function(e) {

                e.preventDefault();

                var quantity = $('input[name="quantity"]').val(),
                    button = $(this),
                    product_id = $(this).val();

                // Optional, will disable and change text of add to cart button on click 
                button.prop('disabled', true).text('Adding...');

                // If overlay exists, let's show it
                if (notification.length > 0) {
                    notification.addClass('bring-forward appear loading');
                }

                $.ajax({
                    url: wc_add_to_cart_params.ajax_url,
                    type: 'POST',
                    data: 'action=mb_wc_add_cart&product_id=' + product_id + '&quantity=' + quantity,
                    timeout: 10000,
                    success: function() {
                        $(document.body).trigger('wc_fragment_refresh');
                        $(document.body).trigger('added_to_cart');
                        $('.woocommerce-notices-wrapper').html('<div class="woocommerce-message clearfix" role="alert">Added to cart! <span class="float-right"><a class="mr-2" href="/shop/">Continue Shopping</a><a class="btn btn-small" href="/cart/">View cart</a></span></div>');
                        $('.quantity .qty').val("1");
                        setTimeout(function() {
                            // $('#exampleModal').modal('show');
                            button.prop('disabled', false).html('<i class="px-4 fas fa-check-circle"></i>');
                        }, 200);
                        setTimeout(function() {
                          button.html('Add to Cart');
                        }, 3000);
                    },
                    error: function(xhr, ajaxOptions, thrownError) {
                        // For debugging, console log any errors. In production, do something different.
                        console.log(xhr.status);
                        console.log(thrownError);
                    }
                });
            });

        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
